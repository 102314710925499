import Cookies from 'js-cookie';
import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
} from "../actionTypes";

const initialState = {
  isLoggedIn: false,
  user: null,
  userRole: null,
  roles: null
};

const user = JSON.parse(localStorage.getItem("userData"));


if (user) {
  initialState.isLoggedIn = true;
  initialState.user = user.user;
  initialState.userRole = user.userRole;
  initialState.roles = user.roles;
}

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_SUCCESS:
      // Update local storage when user logs in
      localStorage.setItem("user", JSON.stringify(payload));
      console.log("payload", payload);
      // Cookies.set('crsttoken', payload.crsttoken, { expires: 7, secure: true, sameSite: 'Strict' });
      // Cookies.set('session', payload.session, { expires: 7, secure: true, sameSite: 'Strict' });
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
        userRole: payload.userRole,
        roles: payload.roles
      };
    case LOGIN_FAIL:
      localStorage.removeItem("userData");
      Cookies.remove('crsttoken');
      Cookies.remove('session');
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        userRole: null,
        roles: null
      };
    case LOGOUT:
      localStorage.removeItem("userData");
      Cookies.remove('crsttoken');
      Cookies.remove('session');
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        userRole: null,
        roles: null
      };
    default:
      return state;
  }
}
