import {
    FETCH_STUDENT,
    FETCH_STUDENT_FAILED,
    SEARCH_STUDENT_SUCCESS,
    SET_MESSAGE,
    UPDATE_STUDENT
} from "../../actionTypes";

import StudentServices from "../../../services/studentServices";

// Fetch student data and store it in Redux
export const fetchStudent = () => async (dispatch) => {
    try {
        // Fetch data from the server
        const data = await StudentServices.getStudents(2017);

        // Dispatch data to Redux store
        dispatch({
            type: FETCH_STUDENT,
            payload: data,
        });
    } catch (error) {
        console.error('Error fetching student data:', error);
        const message = error.response?.data || 'Failed to fetch data';

        // Dispatch failure action
        dispatch({
            type: FETCH_STUDENT_FAILED,
        });

        dispatch({
            type: SET_MESSAGE,
            payload: message,
        });
    }
};

// Update student data in Redux
export const updateStudent = (updatedData) => (dispatch, getState) => {
    const { student } = getState();
    const updatedDataArray = student.data.map(studentItem =>
        studentItem.student_id === updatedData.student_id ? updatedData : studentItem
    );

    // Dispatch update action
    dispatch({
        type: UPDATE_STUDENT,
        payload: updatedDataArray,
    });
};

// Search student data in Redux
export const searchStudent = (searchData) => (dispatch, getState) => {
    const { student } = getState();
    const matchingStudents = student.data.filter(student => {
        const searchTermLowerCase = searchData.toLowerCase();
        return (
            student.student_id.toLowerCase().includes(searchTermLowerCase) ||
            student.first_name.toLowerCase().includes(searchTermLowerCase) ||
            student.last_name.toLowerCase().includes(searchTermLowerCase) ||
            student.email.toLowerCase().includes(searchTermLowerCase)
        );
    });

    // Dispatch search action
    dispatch({ type: SEARCH_STUDENT_SUCCESS, payload: matchingStudents });
};
