import Cookies from 'js-cookie';
import loginServices from "../../../services/loginServices";
import {
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    LOGOUT,
    SET_MESSAGE
} from "../../actionTypes";

export const login = (username, password, email, role) => (dispatch) => {
    if (role === "Admin") {
        const roles = ["privete1", "privete2", "privete7"];
        const userData = {
            user: "Success",
            userRole: role,
            roles: roles,
        };
        dispatch({
            type: LOGIN_SUCCESS,
            payload: userData
        });
        return Promise.resolve();
    } else {
        return loginServices.fetchLogin(username, password, email, role)
            .then((data) => {
                const roles = ["privete1", "privete2", "privete4", "privete8"];

                const { crsttoken, session } = data;

                const userData = { user: data, userRole: role, roles: roles, crsttoken, session };

                Cookies.set('csrftoken', crsttoken, { expires: 7, secure: true, sameSite: 'Strict' });
                Cookies.set('session', session, { expires: 7, secure: true, sameSite: 'Strict' });

                localStorage.setItem('userData', JSON.stringify(userData));

                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: userData,
                });
                return Promise.resolve();
            })
            .catch((error) => {
                const message = error.response?.data || "An error occurred while logging in.";
                dispatch({
                    type: LOGIN_FAIL,
                });
                dispatch({
                    type: SET_MESSAGE,
                    payload: message,
                });
                return Promise.reject(error);
            });
    }
};

export const logout = () => (dispatch) => {
    loginServices.logout();

    Cookies.remove('csrftoken');
    Cookies.remove('session');

    dispatch({
        type: LOGOUT,
    });
};
