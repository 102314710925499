import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { convertSlugToUrl } from '../../utils/utilities';


const MenuComponent = ({ children, isMobile }) => {
    const [isOpen, setIsOpen] = useState(true);
    const navigate = useNavigate();

    function onClick(slug, parameters = {}) {
        navigate(convertSlugToUrl(slug, parameters));
    }
    return (
        //     <div className="sidebar">
        //         {!isOpen ? (
        //             <div onClick={() => setIsOpen(!isOpen)} className="sidebar-small" >
        //                 <img src={smallLogo} alt="MediTask" />
        //             </div>

        //         ) : (
        //             <div onClick={() => setIsOpen(!isOpen)} className="sidebar-big" >
        //                 <img src={logo} alt="MediTask"/>
        //             </div>
        //         )}
        //         {isOpen && children}
        //     </div>
        // );
        <div className="sidebar">

            {children}
        </div>
    );
};

export default MenuComponent;
