import axios from 'axios';

const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

axiosClient.interceptors.request.use((config) => {
    console.log('Request Headers config:', config.headers);
    
    // Retrieve the token from localStorage
    const accessToken = localStorage.getItem('accessToken');

    if (accessToken) {
        config.headers['Authorization'] = accessToken;
    }
    return config;
});

export default axiosClient;
