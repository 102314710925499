// import React from 'react';

// const loadingSpinKeyframes = {
//     from: { transform: 'rotate(0deg)' },
//     to: { transform: 'rotate(360deg)' }
// };

// const loadingStyle = {
//     border: '16px solid lightGrayishBlue',
//     borderRadius: '50%',
//     borderTop: '16px solid brightBlue',
//     width: 120,
//     height: 120,
//     animationName: 'loadingSpin',
//     animationTimingFunction: 'linear',
//     animationDuration: '2s',
//     animationIterationCount: 'infinite'
// };

// const loadingSpanStyle = {
//     color: 'black',
//     marginTop: 10,
//     fontSize: 18
// };

// function LoadingComponent({
//     backgroundColor,
//     children,
//     fullScreen,
//     height,
//     hideText,
//     loading,
//     noTransparency,
//     width,
//     zIndex
// }) {
//     const containerStyle = {
//         backgroundColor: backgroundColor
//             ? noTransparency
//                 ? backgroundColor
//                 : `${backgroundColor}A0`
//             : noTransparency
//             ? 'veryDarkGrayishBlue'
//             : 'veryDarkGrayishBlueA0',
//         height: fullScreen ? '100vh' : '100%',
//         minHeight: fullScreen ? '100vh' : '100%',
//         width: fullScreen ? '100vw' : '100%',
//         position: fullScreen ? 'fixed' : 'relative',
//         top: 0,
//         left: 0,
//         zIndex: zIndex
//     };

//     return (
//         <div style={{ position: 'relative', height, width }}>
//             {loading && (
//                 <div style={containerStyle}>
//                     <div style={loadingStyle}></div>
//                     {!hideText && <span style={loadingSpanStyle}>Loading...</span>}
//                 </div>
//             )}
//             {children || <div />}
//         </div>
//     );
// }

// LoadingComponent.defaultProps = {
//     fullScreen: true,
//     zIndex: 10
// };

// export default LoadingComponent;


import React from "react";
import "./spinner.css";

function LoadingComponent() {
    return (
        <div className="spinner-container">
            <div className="loading-spinner"></div>
        </div>
    );
}

export default LoadingComponent;