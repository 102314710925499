import React from 'react';
import { useLocation } from 'react-router-dom';
import CollapsibleContent from '../collapsible/CollapsibleContent';
import { useSidebar } from '../../constant/useSidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function MenuItemComponent({ children, icon: Icon, id, items = [], level = 1, onClick, title, isLogoVisible, defaultExpanded }) {
    const location = useLocation();
    const { isExpanded, isActive, onItemClick } = useSidebar({
        isCollapsible: children && children.length > 0,
        item: id,
        items
    });

    const isCollapsible = children && children.length > 0;
    const isCurrentlyActive = location.pathname.startsWith(id) || location.pathname === id;

    const containerStyle = {
        display: 'flex',
        cursor: 'pointer',
        padding: 15,
        transition: 'all 0.2s ease-in-out',
        backgroundColor: isCurrentlyActive ? '#FAF4FC' : 'transparent',
        boxShadow: isCurrentlyActive ? '1px 2px 9px #FAF4FC' : 'none',
        fontWeight: isCurrentlyActive ? '550' : '400',
        justifyContent: 'center',
        borderRadius: '1rem',
    };

    const titleStyle = {
        fontSize: 16,
        marginLeft: 15,
        lineHeight: '20px',
        letterSpacing: '0.2px',
        color: '#131523'
    };

    function onItemClicked(e) {
        if (onClick) {
            onClick(e);
        }
        onItemClick();
    }

    return (
        <div key={id} className="sidebar-icon">
            <div style={containerStyle} onClick={onItemClicked} className='flex items-center'>
                <div className="h-full flex justify-center">
                    <FontAwesomeIcon icon={Icon} opacity={!isCurrentlyActive && '0.4'} />
                </div>
                <span style={titleStyle} className="flex-auto">{title}</span>
            </div>

            {isCollapsible && (
                <CollapsibleContent expanded={defaultExpanded || isExpanded}>
                    {children.map((child) => child.type({ ...child.props }))}
                </CollapsibleContent>
            )}
        </div>
    );
}

export default MenuItemComponent;