import React, { Suspense, lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { publicSLUGS as SLUGS } from '../utils/slugs';
import LoadingComponent from '../components/loading';

// Lazy loading for login component
const LoginComponent = lazy(() => import('../routes/login'));

function PublicRoutes({ onLoginSuccess }) {
    return (
        <Suspense fallback={<LoadingComponent loading />}>
            <Routes>
                <Route path={SLUGS.login.path} element={<LoginComponent onLoginSuccess={onLoginSuccess} />} />

                <Route path="*" element={<Navigate to={SLUGS.login.path} />} />
            </Routes>
        </Suspense>
    );
}

export default PublicRoutes;
