import axiosClient from './client';
import Cookies from 'js-cookie';

const getStudents = (student_id) => {
  return axiosClient
    .post(process.env.REACT_APP_API_URL + "/student/all/", {
      "year": student_id
    })
    .then((response) => {

      console.log("Student data", response.data)

      return response.data;
    });
};

export default {
  getStudents
};