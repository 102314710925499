
import {
  FETCH_LOG,
  FETCH_LOG_FAILED,
  SEARCH_LOGS_SUCCESS
} from "../actionTypes";


export default function (state = [], action) {
  const { type, payload } = action;
  const data = {}
  switch (type) {
    case FETCH_LOG:
      return {
        ...state,
        data: payload.data,
      };
    case SEARCH_LOGS_SUCCESS:
      return {
        ...state,
        data: payload.data,
      };
    case FETCH_LOG_FAILED:
      return { ...state };
    default:
      return state;
  }
}