import React, { Suspense, lazy, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { privateSLUGS as SLUGS } from '../utils/slugs';
import LoadingComponent from '../components/loading';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStudent } from '../redux/actions/student';



function PrivateRoutes() {
    const dispatch = useDispatch();
    const studentData = useSelector(state => state.student.data);
    const loading = useSelector(state => state.student.loading); // Assuming you have a loading state

    useEffect(() => {
        if (!studentData || studentData.length === 0) {
            dispatch(fetchStudent());
        }
    }, [dispatch, studentData]);

    if (loading) {
        return <LoadingComponent loading />;
    }
    return (
        <Suspense fallback={<LoadingComponent loading />}>
            <Routes>
                {Object.values(SLUGS).map(slug => (
                    <Route
                        key={slug.path}
                        path={slug.path}
                        element={React.createElement(slug.component)}
                    />
                ))}
                <Route path="*" element={<Navigate to={SLUGS.dashboard.path} />} />
            </Routes>
        </Suspense>
    );
}

export default PrivateRoutes;
