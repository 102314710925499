
import {
  FETCH_VERILOG,
  FETCH_VERILOG_FAILED,
} from "../actionTypes";


export default function (state = [], action) {
  const { type, payload } = action;
  const data = {}
  switch (type) {
    case FETCH_VERILOG:
      return {
        ...state,
        data: payload.data,
      };
    case FETCH_VERILOG_FAILED:
      return { ...state };
    default:
      return state;
  }
}