export const FETCH_YEARS = "FETCH_YEARS";
export const FETCH_YEARS_FAILED = "FETCH_YEARS_FAILED";

export const FETCH_VERILOG = "FETCH_VERILOG";
export const FETCH_VERILOG_FAILED = "FETCH_VERILOG_FAILED";

export const FETCH_TASK = "FETCH_TASK";
export const FETCH_TASK_FAILED = "FETCH_TASK_FAILED";

export const FETCH_STUDENT = "FETCH_STUDENT";
export const FETCH_STUDENT_FAILED = "FETCH_STUDENT_FAILED";
export const UPDATE_STUDENT = "UPDATE_STUDENT";
export const SEARCH_STUDENT_SUCCESS = "SEARCH_STUDENT_SUCCESS";

export const FETCH_LOG = "FETCH_LOG";
export const FETCH_LOG_FAILED = "FETCH_LOG_FAILED";
export const SEARCH_LOGS_SUCCESS = "SEARCH_LOGS_SUCCESS";

export const FETCH_HOSPITALS = "FETCH_HOSPITALS";
export const FETCH_HOSPITALS_FAILED = "FETCH_HOSPITALS_FAILED";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const UPDATE_CERTIFICATE_STATUS = 'UPDATE_CERTIFICATE_STATUS';
export const UPDATE_CERTIFICATE_STATUS_FAILED = 'UPDATE_CERTIFICATE_STATUS_FAILED';
