
import axiosClient from './client';


function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}


const fetchLogin = async (username, password, email, role) => {
    try {
        const loginData = {
            username: username,
            password: password,
            email: email,
        };

        const response = await axiosClient.post('/login/', loginData, { withCredentials: true });
        const accessToken = response.data.access;

        if (accessToken) {
            localStorage.setItem('accessToken', accessToken); // Store the token in localStorage
            return { user: response.data };
        } else {
            throw new Error('Access token not found in the response');
        }
    } catch (error) {
        console.log(process.env.REACT_APP_API_URL+"/login/")
        console.log('Error fetching login data: ', error.message, );
        throw new Error('Error fetching login data: ' + error.message);
    }
};


const logout = () => {
    console.log("LOGOUT");
};

export default {
    fetchLogin,
    logout,
};
