import React, { useEffect, useRef, useState } from 'react';

const STATUS = {
    COLLAPSED: 'COLLAPSED',
    PRE_EXPANDED: 'PRE_EXPANDED',
    EXPANDED: 'EXPANDED',
    PRE_COLLAPSED: 'PRE_COLLAPSED'
};

function CollapsibleContent({
    children,
    expanded,
    style,
    transitionDuration = '.425s',
    transitionTimingFunction = 'ease-in-out',
    onTransitionStart,
    onTransitionEnd,
    ...others
}) {
    const contentContainerRef = useRef(null);
    const [status, setStatus] = useState(expanded ? STATUS.EXPANDED : STATUS.COLLAPSED);

    useEffect(() => {
        const contentContainer = contentContainerRef.current;
        if (expanded) {
            if (status === STATUS.COLLAPSED) {
                setStatus(STATUS.PRE_EXPANDED);
                if (onTransitionStart) onTransitionStart();
                contentContainer.style.maxHeight = `${contentContainer.scrollHeight}px`;
            } else if (status === STATUS.PRE_EXPANDED) {
                setStatus(STATUS.EXPANDED);
                if (onTransitionEnd) onTransitionEnd();
            }
        } else if (!expanded) {
            if (status === STATUS.EXPANDED) {
                setStatus(STATUS.PRE_COLLAPSED);
                if (onTransitionStart) onTransitionStart();
                contentContainer.style.maxHeight = `${contentContainer.scrollHeight}px`;
            } else if (status === STATUS.PRE_COLLAPSED) {
                setStatus(STATUS.COLLAPSED);
                if (onTransitionEnd) onTransitionEnd();
                contentContainer.style.maxHeight = '0';
            }
        }
    }, [expanded, status, onTransitionStart, onTransitionEnd]);

    return (
        <div
            {...others}
            style={{
                overflow: 'hidden',
                transitionProperty: 'max-height',
                transitionTimingFunction,
                transitionDuration,
                maxHeight: status === STATUS.EXPANDED || status === STATUS.PRE_EXPANDED ? '100vh' : '0',
                ...style
            }}
            ref={contentContainerRef}
        >
            {children}
        </div>
    );
}

export default CollapsibleContent;
