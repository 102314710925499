import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function DropdownComponent({ label, options, onClick }) {
    const [userMenuOpen, setUserMenuOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setUserMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const onDropdownClick = () => {
        setUserMenuOpen((prev) => !prev);
    };

    const handleOptionClick = (optionOnClick, index) => {
        console.log(optionOnClick)
        console.log(index)
        setUserMenuOpen(false);
        optionOnClick && optionOnClick();
    };

    return (
        <div className="w-full relative" ref={dropdownRef}>
            <button onClick={onDropdownClick} className="">
                {label}
            </button>
            {userMenuOpen && (
                <div className="absolute w-full rounded-3xl bg-white text-sm shadow-lg ring-1 ring-gray-900/5 p-4"  style={{ zIndex: 999 }}>
                    {options.map((option, index) => (
                        <div
                            key={index}
                            className="rounded-lg p-4 hover:bg-gray-50"
                            onClick={() => handleOptionClick(option.onClick, index)}
                        >
                            <div className="font-semibold text-gray-900 cursor-pointer">
                                <FontAwesomeIcon icon={option.icon} />
                                <span className="ml-2">{option.label}</span>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default DropdownComponent;
