export function convertSlugToUrl(slug, parameters, data = {}) {
    let url = slug;
    Object.entries(parameters).forEach(([key, value]) => {
        url = url.replace(`:${key}`, value);
    });

    return url;
}

export function calculateTotalAwaitingVerification(studentData) {
    return studentData.reduce((acc, student) => {
        const awaitingVerification = student.mandatory_trainings.filter(cert => cert.upload_status === 'Uploaded' && cert.verified_date === null).length;
        return acc + awaitingVerification;
    }, 0);
}

export const calculateStudentYear = (enrolmentDate) => {
    const enrolmentYear = new Date(enrolmentDate).getFullYear();
    const currentYear = new Date().getFullYear();
    return currentYear - enrolmentYear + 1;
};