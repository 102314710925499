import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SearchBar = ({ searchTerm, handleInputChange, handleInputBlur, suggestions, loading, handleSuggestionClick }) => {
    return (
        <div className="flex-auto relative mx-5 ">
            <div className="rounded-lg focus-within:shadow-lg bg-white">
                <div className="flex flex-row w-auto p-2">
                    <div className="text-gray-300">

                        <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />
                    </div>
                    <input
                        type="text"
                        placeholder="Search"
                        className="outline-none text-sm text-gray-700 ml-2 w-full"
                        value={searchTerm}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                    />
                </div>
            </div>
            {suggestions.length > 0 && (
                <ul className="absolute top-full left-0 w-full bg-white text-gray-700 rounded-md mt-1 ">
                    {loading ? (
                        <div>Loading...</div>
                    ) : (
                        suggestions.map((suggestion, index) => (
                            <li
                                key={index}
                                className="flex justify-between gap-x-6 py-5 cursor-pointer hover:bg-[#FAF4FC] hover:text-gray-700 px-5"
                                onClick={() => handleSuggestionClick(suggestion)}
                            >
                                <div className="flex min-w-0 gap-x-4">
                                    <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={suggestion.image} alt="" />
                                    <div className="min-w-0 flex-auto">
                                        <p className="text-sm font-semibold leading-6 text-gray-900">{suggestion.first_name} {suggestion.last_name}</p>
                                        <p className="mt-1 truncate text-xs leading-5 text-gray-500">{suggestion.email}</p>
                                    </div>
                                </div>
                                <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                                    <p className="text-sm leading-6 text-gray-900">{suggestion.student_id}</p>
                                </div>
                            </li>
                        ))
                    )}
                </ul>
            )}

        </div>
    );
};

export default SearchBar;
