import { lazy } from 'react';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
const DashboardComponent = lazy(() => import('../routes/dashboard'));
const StudentComponent = lazy(() => import('../routes/student/StudentComponent'));
const StudentDetailComponent = lazy(() => import('../routes/student/StudentDetailComponent'))
const AssessmentComponent = lazy(() => import('../routes/assessment/AssessmentComponent'));
const AssessmentDetailComponent = lazy(() => import('../routes/assessment/AssessmentDetailComponent'));
const UserProfileComponent = lazy(() => import('../routes/userprofile/UserProfileComponent'));
const AccountComponent = lazy(() => import('../routes/accountmanagement/AccountComponent'))
const AllStudentsComponent = lazy(() => import('../routes/mandatorytraining/AllStudentsComponent'));
const MandatoryTrainingStudentDetailComponent = lazy(() => import('../routes/mandatorytraining/StudentDetailComponent'))
const LoginComponent = lazy(() => import('../routes/login'));


// Public Routes
const publicSLUGS = {
    login: {
        path: '/login',
        menuTitle: 'Login',
        pageTitle: 'Login',
        icon: 'login-icon'
    },
    signup: {
        path: '/signup',
        menuTitle: 'Sign Up',
        pageTitle: 'Sign Up',
        icon: 'signup-icon'
    },
    forgotPassword: {
        path: '/forgot_password',
        menuTitle: 'Forgot Password',
        pageTitle: 'Forgot Password',
        icon: 'forgot-password-icon'
    }
};

// Private Routes
const privateSLUGS = {
    dashboard: {
        path: '/dashboard',
        component: DashboardComponent,
        menuTitle: 'Dashboard',
        pageTitle: 'Dashboard',
        menuId: "privete1",
        icon: 'fa-house'
    },
    student: {
        path: '/student',
        component: StudentComponent,
        menuTitle: 'Student',
        pageTitle: 'Student',
        menuId: "privete2",
        icon: 'fa-graduation-cap'
    },
    studentDetail: {
        path: '/student/detail',
        component: StudentDetailComponent,
        menuTitle: '',
        pageTitle: 'Student Detail',
        menuId: "privete3",
        icon: 'student-detail-icon'
    },
    assessment: {
        path: '/assessment',
        component: AssessmentComponent,
        menuTitle: 'Assessment',
        pageTitle: 'Assessment',
        menuId: "privete4",
        icon: 'fa-flask'
    },
    assessmentDetail: {
        path: '/assessment/detail',
        component: AssessmentDetailComponent,
        menuTitle: '',
        pageTitle: 'Assessment Detail',
        menuId: "privete5",
        icon: 'assessment-detail-icon'
    },
    userProfile: {
        path: '/user-profile',
        component: UserProfileComponent,
        menuTitle: '',
        pageTitle: 'User Profile',
        menuId: "privete6",
        icon: 'fa-regular fa-user'
    },
    accountManagement: {
        path: '/account-management',
        component: AccountComponent,
        menuTitle: 'Account Management',
        pageTitle: 'Account Management',
        menuId: "privete7",
        icon: 'fa-solid fa-envelope'
    },
    mandatoryTraining: {
        path: '/mandatory-training',
        component: AllStudentsComponent,
        menuTitle: 'Mandatory Training',
        pageTitle: 'Mandatory Training',
        menuId: "privete8",
        icon: "fa-solid fa-award",
    },
    mandatoryTrainingStudentDetail: {
        path: '/mandatory-training/all-students/student-detail',
        component: MandatoryTrainingStudentDetailComponent,
        menuTitle: '',
        pageTitle: 'Student Detail',
        menuId: "privete8-1-1",
    },
};

export { publicSLUGS, privateSLUGS };
