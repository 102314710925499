
import {
    FETCH_STUDENT,
    FETCH_STUDENT_FAILED,
    SEARCH_STUDENT_SUCCESS,
    UPDATE_CERTIFICATE_STATUS,
    UPDATE_CERTIFICATE_STATUS_FAILED,
    UPDATE_STUDENT
} from "../actionTypes";

const initialState = {
    data: [],
    error: null,
};

export default function student(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case FETCH_STUDENT:
        case SEARCH_STUDENT_SUCCESS:
        case UPDATE_STUDENT:
            return {
                ...state,
                data: payload,
                error: null,
            };
        case UPDATE_CERTIFICATE_STATUS:
            return {
                ...state,
                data: state.data.map(student => ({
                    ...student,
                    mandatory_trainings: student.mandatory_trainings.map(cert =>
                        cert.id === payload.id ? { ...cert, status: payload.status } : cert
                    ),
                })),
                error: null,
            };
        case FETCH_STUDENT_FAILED:
        case UPDATE_CERTIFICATE_STATUS_FAILED:
            return {
                ...state,
                error: payload,
            };
        default:
            return state;
    }
}
