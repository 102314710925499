import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import useWindowSize from '../constant/useWindowSize';
import { privateSLUGS, publicSLUGS } from '../utils/slugs';
import PrivateSection from './PrivateSection';
import PublicRoutes from './PublicRoutes';

function Routes() {
    // const [isLoggedIn, setIsLoggedIn] = useState(true);
    // const userRole = ["privete1", "privete2", "privete4"];
    const { isLoggedIn, userRole } = useSelector(state => state.auth);
    const { pathname } = useLocation();
    const [width, height] = useWindowSize();
    const navigate = useNavigate();
    const { message } = useSelector(state => state.message);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const handleLoginSuccess = () => {
        navigate(privateSLUGS.dashboard);

    };

    const handleLogout = () => {
        navigate(publicSLUGS.login);
    };

    return isLoggedIn ? <PrivateSection onLogout={handleLogout} userRole={userRole} /> : <PublicRoutes onLoginSuccess={handleLoginSuccess} />;
}

export default Routes;
