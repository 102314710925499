
import {
  FETCH_YEARS,
  FETCH_YEARS_FAILED
} from "../actionTypes";


export default function (state = [], action) {
  const { type, payload } = action;
  const data = {}
  switch (type) {
    case FETCH_YEARS:
      return {
        ...state,
        data: payload.data,
      };
    case FETCH_YEARS_FAILED:
      return { ...state };
    default:
      return state;
  }
}