import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { privateSLUGS as SLUGS } from '../../utils/slugs';

import logo from '../../assets/logo.png';
import smallLogo from '../../assets/smallLogo.png';

import { fetchStudent } from '../../redux/actions/student';
import { calculateTotalAwaitingVerification, convertSlugToUrl } from '../../utils/utilities';
import Menu from './MenuComponent';
import MenuItem from './MenuItemComponent';

function SidebarComponent() {
    const navigate = useNavigate();
    const location = useLocation();
    const isMobile = window.innerWidth <= 1080;
    const [isOpen, setIsOpen] = useState(true);
    const { isLoggedIn, userRole, roles } = useSelector(state => state.auth);

    // Retrieve studentData from Redux store
    const studentData = useSelector(state => state.student.data);
    const [totalAwaitingVerification, setTotalAwaitingVerification] = useState(0);

    const dispatch = useDispatch();

    useEffect(() => {
        // Fetch student data if it's not available
        if (!studentData.length) {
            dispatch(fetchStudent());
        }
    }, [dispatch, studentData.length]);

    useEffect(() => {
        if (studentData) {
            // Calculate total awaiting verifications from studentData
            const totalAwaiting = calculateTotalAwaitingVerification(studentData);
            setTotalAwaitingVerification(totalAwaiting);
        }
    }, [studentData]);

    const shouldShowPrivateSlug = (slug) => {
        return slug.menuTitle !== '' && roles.includes(slug.menuId);
    }

    const filteredPrivateSLUGS = Object.values(SLUGS).filter(shouldShowPrivateSlug);

    function handleClick(slug, parameters = {}) {
        navigate(convertSlugToUrl(slug.path, parameters));
    }

    return (
        <Menu isMobile={isMobile}>
            {!isOpen ? (
                <div onClick={() => setIsOpen(!isOpen)} className="sidebar-small">
                    <img src={smallLogo} alt="MediTask" />
                </div>
            ) : (
                <div onClick={() => setIsOpen(!isOpen)} className="sidebar-big">
                    <img src={logo} alt="MediTask" />
                </div>
            )}
            {filteredPrivateSLUGS.map(slug => {
                if (slug.path === '/mandatory-training') {
                    return (
                        <MenuItem
                            key={slug.path}
                            id={slug.path}
                            title={
                                <div className="flex items-center">
                                    <span className="mr-2">{slug.menuTitle}</span>
                                    <div className="flex items-center justify-center w-6 h-6 rounded-full bg-red-600 text-white text-xs font-bold">
                                        {totalAwaitingVerification}
                                    </div>
                                </div>
                            }
                            icon={slug.icon}
                            onClick={() => handleClick(slug)}
                            isLogoVisible={isOpen}
                        />
                    );
                } else {
                    return (
                        <MenuItem
                            key={slug.path}
                            id={slug.path}
                            title={slug.menuTitle}
                            icon={slug.icon}
                            onClick={() => handleClick(slug)}
                            isLogoVisible={isOpen}
                        />
                    );
                }
            })}
        </Menu>
    );
}

export default SidebarComponent;
