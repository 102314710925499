import React from 'react';
import { SidebarComponent, SidebarContext } from '../components/sidebar';
import HeaderComponent from '../components/header/HeaderComponent';
import PrivateRoutes from './PrivateRoutes';

function PrivateSection({ onLogout }) {

    return (
        <div className="flex h-screen md:flex-row">
            <SidebarContext>
                <div className="px-5"> 
                    <SidebarComponent />
                </div>

                <div className="flex flex-col w-full">
                    <div className="header-layout">
                        <HeaderComponent onLogout={onLogout}/>
                    </div>
                    <div className="main-layout flex-grow px-4 md:px-8 py-4"> 
                        <PrivateRoutes />
                    </div>
                </div>
            </SidebarContext>
        </div>
    );
}

export default PrivateSection;

